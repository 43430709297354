<script lang="ts" generics="T">
  import { type Snippet } from 'svelte'
  import type { HTMLSelectAttributes } from 'svelte/elements'

  interface Props {
    class?: string
    value: T | null
    children: Snippet
  }

  let {
    class: cssClass = '',
    children,
    value = $bindable(),
    ...restProps
  }: Props & HTMLSelectAttributes = $props()
</script>

<select {...restProps} bind:value class="select {cssClass ?? ''}">
  {@render children()}
</select>

<style>
  select {
    width: fit-content;
    padding: 0.5rem 0.25rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 0.25rem;
  }
</style>
